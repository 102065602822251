import store from '@/store'
import ROLES from '@/utils/roles.json'

export default {
  methods: {
    goBack() {
      this.$router.go(-1)
    },

    getAssetStatusColor(status) {
      status = status.toLowerCase()

      if (status === 'available') return 'badge--green'
      if (status === 'rented') return 'badge--yellow'
      if (status === 'reserved for rent') return 'badge--red'
      if (status === 'machine down') return 'badge--red'
    },

    getContractStatusColor(status) {
      status = status.toLowerCase()

      if (status === '0') return 'badge--green'
      if (status === '1') return 'badge--blue'
      if (status === '2') return 'badge--pink'
      if (status === '3') return 'badge--red'
    },

    getRequestStatusIcon(request) {
      if (request.status !== 'archived') {
        if (request.status === 'active') return 'success'
        if (request.status === 'pending') return 'pending'
      }

      return request.declineReason ? 'error' : 'active'
    },

    isTablet() {
      return window.innerWidth <= 992
    },
    isMobile() {
      return window.innerWidth <= 576
    },
    removeSpaces(str) {
      return str.replace(/ /g, '')
    },

    getFilesAmount(folders) {
      let count = 0

      for (let item of folders) {

        const getChilds = folder => {
          folder.childs.forEach(child => {
            if (child.type === 'folder') {
              getChilds(child)
            }
            if (child.type === 'file') count++
          })
        }

        if (item.type === 'file') {
          count++
        }

        if (item.type === 'folder') {
          getChilds(item)
        }
      }

      return count
    },

    base64ToImage(base) {
      return `data:image/png;base64,${base}`
    }

  },
  computed: {
    isAdmin() {
      return store.getters.USER.role.includes(ROLES.ADMIN)
    }
  }
}