<template>
  <div class="file-viewer">
    <vs-button @click="showFiles" block>View files ({{ getFilesAmount(files.childs) }})</vs-button>

    <vs-dialog class="file-viewer__documents" :loading="IS_LOADING" v-model="filesPopupActive" width="640px" not-center
      :class="{ 'file-viewer__documents--empty': getFilesAmount(files.childs) === 0 }">
      <div class="dialog border flex flex--direction--column gap--24">
        <div class="f--20 f--700">Documents</div>
        <mn-files :files="files" @onPDFClick="showPDF"></mn-files>
      </div>
    </vs-dialog>

    <vs-dialog width="1440px" not-center v-model="pdfViewerPopupActive" class="file-viewer__pdf">
      <div class="dialog">
        <mn-pdf-viewer :path="currentPDFPath"></mn-pdf-viewer>
      </div>
    </vs-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    files: {
      require: false,
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      filesPopupActive: false,
      pdfViewerPopupActive: false,
      currentPDFPath: null,
    }
  },
  computed: mapGetters(['IS_LOADING']),
  methods: {
    showPDF(path) {
      this.currentPDFPath = path
      this.pdfViewerPopupActive = true
    },
    showFiles() {
      this.filesPopupActive = true
    },
  }
}
</script>

<style lang="scss">
.file-viewer {
  width: 100%;

  &__documents {
    .vs-dialog__close {
      top: 6px;
      right: 6px;
    }

    .dialog {
      height: 70vh;
    }

  }

  &__pdf {
    .vs-dialog {
      max-width: 90vw;

      &__close {
        right: 0;
        top: -40px;
      }

      .dialog {
        padding: 0;
        height: calc(100vh - 100px);

        @supports (-webkit-touch-callout: none) {
          height: calc(100dvh - 100px);
        }
      }
    }
  }

  @media only screen and (max-width: 992px) and (orientation: landscape) {
    &__documents {
      .dialog {
        height: auto;
      }
    }
  }
}
</style>