import '@/utils/log'

import Vue from 'vue'

import router from './router'
import store from './store'
import mixins from '@/mixins/mixins'

import App from './App.vue'

import Vue2TouchEvents from 'vue2-touch-events'
import ElementUI from 'element-ui';
import VueMeta from 'vue-meta'
import Vuesax from 'vuesax'
import VueNumber from 'vue-number-animation'
import VueCarousel from 'vue-carousel';
import PrimeVue from 'primevue/config';

import '@/elements'
import '@/filters'
import "@/styles/global.scss";
import "@/styles/vars.scss";

Vue.config.productionTip = false

Vue.use(Vuesax)
Vue.use(VueMeta)
Vue.use(VueNumber)
Vue.use(Vue2TouchEvents)
Vue.mixin(mixins)
Vue.use(ElementUI)
Vue.use(VueCarousel)
Vue.use(PrimeVue)

document.documentElement.setAttribute('mn-theme', store.getters.THEME)
document.documentElement.setAttribute('vs-theme', store.getters.THEME);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

