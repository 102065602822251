<template>
  <div v-if="USER" class="admin flex flex--grow--1 flex--items--stretch" :class="{ 'sidebar--open': isSidebarOpen }">

    <sidebar @onLogout="logout" :open.sync="isSidebarOpen" :username="USER?.name">
    </sidebar>

    <div ref="content"
      class="content w100 flex flex--direction--column flex--content--start flex--items--start flex--grow--1">
      <header class="header header--sticky block flex flex--content--between flex--items--center">
        <div @click="toggleSidebar" :class="[{ 'is-active': isSidebarOpen }, getHamburgerType()]"
          class="hamburger flex flex--center" type="button">
          <div class="icon icon--mask icon--mask--menu"></div>
        </div>

      </header>

      <router-view></router-view>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import sidebar from '@/layouts/sidebar.vue'

export default {
  data() {
    return {
      isSidebarOpen: false,
      loader: ''
    }
  },
  components: {
    sidebar
  },
  methods: {
    async logout() {
      await this.$store.dispatch('AUTH_LOGOUT')
      this.$router.push({ name: 'login' })
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
    },
    getHamburgerType() {
      return window.innerWidth <= 576 ? 'hamburger--collapse' : 'hamburger--arrowalt'
    }
  },
  watch: {
    IS_LOADING() {
      if (this.IS_LOADING) {
        this.loader = this.$vs.loading({
          target: this.$refs.content
        })
      } else {
        this.loader.close()
      }
    },
    isSidebarOpen() {
      this.isSidebarOpen
        ? document.body.classList.add('static')
        : document.body.classList.remove('static')
    }
  },

  computed: {
    ...mapGetters(['IS_LOADING', 'USER'])
  },

  created() {
    if (!this.isTablet()) {
      this.isSidebarOpen = true
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isTablet()) {
      this.isSidebarOpen = false
    }

    next()
  }
}
</script>

<style lang="scss">
.admin {
  --sidebar-width: 320px;

  margin-left: var(--sidebar-width);

  .content {
    position: relative;

    .vs-loading {
      position: fixed;
      width: auto;
      right: 0;
      bottom: 0;
      z-index: 1;
      left: var(--sidebar-width);
    }
  }

  .header {
    .hamburger {
      cursor: pointer;
      padding: 16px;
      margin-left: -16px;
      display: none;

      .icon--mask {
        width: 21px;
        height: 18px;
      }
    }
  }

  @include _992 {
    margin-left: 0;

    .header {
      .hamburger {
        display: flex;
      }
    }

    .content {
      .vs-loading {
        left: 0;
      }
    }
  }
}
</style>