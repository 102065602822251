

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import setupInterceptors from '@/utils/interceptors'

import user from "./modules/user";
import auth from "./modules/auth";
import assets from "./modules/assets";
import contracts from "./modules/contracts";
import requests from "./modules/requests";
import common from "./modules/common";

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    user,
    auth,
    assets,
    contracts,
    requests,
    common
  },
  strict: debug
})

setupInterceptors(store)

export default store