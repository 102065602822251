import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '../pages/Admin.vue'
import store from "../store";
import Permissions from '@/utils/permissions';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'admin',
    component: Admin,
    redirect: '/dashboard',
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: {
          title: 'Dashboard',
          requiresAuth: true
        }
      },
      {
        path: '/assets',
        name: 'assets',
        component: () => import('../views/Assets.vue'),
        meta: {
          title: 'Assets',
          requiresAuth: true
        }
      },
      {
        path: '/assets/:id',
        name: 'asset',
        component: () => import('../views/Asset.vue'),
        meta: {
          title: 'Asset',
          requiresAuth: true
        }
      },
      {
        path: '/contracts',
        name: 'contracts',
        component: () => import('../views/Contracts.vue'),
        meta: {
          title: 'Contracts',
          requiresAuth: true
        }
      },
      {
        path: '/contracts/:id',
        name: 'contract',
        component: () => import('../views/Contract.vue'),
        meta: {
          title: 'Contract',
          requiresAuth: true
        }
      },
      {
        path: '/requests',
        name: 'requests',
        component: () => import('../views/Requests.vue'),
        meta: {
          title: 'Requests',
          requiresAuth: true
        }
      },
      {
        path: '/requests/:id',
        name: 'request',
        component: () => import('../views/Request.vue'),
        meta: {
          title: 'Request',
          requiresAuth: true
        }
      },
      {
        path: '/request-new/',
        redirect: '/assets',
        meta: {
          title: 'New Request',
          requiresAuth: true
        }
      },
      {
        path: '/request-new/:id',
        name: 'request-new',
        component: () => import('../views/RequestNew.vue'),
        meta: {
          title: 'New Request',
          requiresAuth: true
        }
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/Users.vue'),
        meta: {
          title: 'Users',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../pages/Login.vue'),
    meta: {
      title: 'Log in',
      requiresGuest: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../pages/ResetPassword.vue'),
    meta: {
      title: 'Reset password',
      requiresGuest: true
    },
  },
  {
    path: '/reset-password/:id',
    name: 'reset-password-approve',
    component: () => import('@/pages/ResetPasswordApprove.vue'),
    meta: {
      title: 'Reset password approving',
      requiresGuest: true
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/pages/error/_404.vue'),
    meta: {
      title: 'Page not found'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Mid-North App';
  });
})

router.beforeEach(async (to, from, next) => {

  console.log(`Router changed, going to: ${to.name}`)

  store.getters.BLOCK_REQUESTS.forEach(request => {
    try {
      request.controller.abort()
      // console.log(`Cancel block request in router, id: ${request.id}`)
    } catch (err) {
      // console.log(`Cant cancel block request in router, id: ${request.id}`)
    }

    store.commit('REMOVE_BLOCK_REQUEST', request.id)
  })

  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth)
  const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);

  if (requiresAuth) {
    if (store.getters.TOKEN) { 
      const user = store.getters.USER

      if (user) {
        console.log('User is found, next')

        if (Permissions.hasPermission(user, to)) {
          next()

          if (to.name !== 'dashboard' && to.name !== from.name) {
            store.dispatch('GET_DASHBOARD_INFO')
          }

        } else {
          next({ name: 'dashboard' })
        }
        next()
      } else {
        console.log('User not found, get user data...')

        try {
          const user = await store.dispatch('GET_USER')
          console.log('User getted, next')

          if (Permissions.hasPermission(user, to)) {

            if (to.name !== 'dashboard' && to.name !== from.name) {
              store.dispatch('GET_DASHBOARD_INFO')
            }

            next()
          } else {
            next({ name: 'dashboard' })
          }
        } catch (err) {
          console.log(err)
        }

      }
    } else {
      next({ name: 'login' })
    }
  }

  if (requiresGuest) {
    if (store.getters.TOKEN) {
      next("/")
    } else {
      next()
    }
  }

  next()

})

export default router
