import { permissionRules } from '@/utils/permissionRules'

export default {
  hasPermission(user, to) {
    let canNext = false

    user.role.forEach(role => {

      const permission = permissionRules.find(p => p.role === role)

      if (permission) {

        if (!permission.restricted.routes.includes(to.name)) {
          canNext = true
        }

      } else {
        canNext = true
        console.log(`Permission not found for user rol: ${role}`)
      }
    })

    console.log(`Permission check result: ${canNext}`)

    return canNext
  }
}