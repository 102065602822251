<template>
  <div class="flex flex--direction--column flex--grow--1">
    <div @click="toggleTheme" class="theme-toggle flex flex--center">
      <div v-if="THEME === 'dark'" class="icon icon--mask icon--mask--theme-light"></div>
      <div v-else class="icon icon--mask icon--mask--theme-dark"></div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['THEME']),
  methods: {
    toggleTheme() {
      this.$store.commit('SET_THEME', this.THEME === 'dark' ? 'light' : 'dark')

      const root = document.documentElement

      root.setAttribute('mn-theme', this.THEME)
      root.setAttribute('vs-theme', this.THEME)
      root.classList.add('vs-remove-transition')

      setTimeout(() => {
        root.classList.remove('vs-remove-transition')
      }, 125)
    }
  }
}
</script>

<style lang="scss">
.theme-toggle {
  position: fixed;
  top: 12px;
  right: 12px;
  padding: 8px;
  z-index: 3;
  cursor: pointer;

  &:hover {
    .icon--mask {
      background-color: var(--mn-yellow);
    }
  }
}
</style>