<template>
  <vs-button :disabled="disabled" border block @click="copy(number)" class="copy-number"
    :class="{ 'filter--grayscale': disabled }">
    <div class="flex flex--content--between flex--grow--1 gap--8">
      {{ prefix }} {{ number }}
      <div class="icon icon--mask icon--mask--copy"></div>
    </div>
  </vs-button>
</template>

<script>
import helpers from '@/utils/helpers';

export default {
  props: {
    prefix: {
      type: String,
      required: false,
      default: ""
    },
    number: {
      type: String,
      required: true,
      default: "-"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    copy(id) {
      helpers.copyToClipboard(id)
        .then(res => {
          this.$vs.notification({
            text: `Serial number copied to clipboard!`,
          })
        })
        .catch(err => { })
    }
  }
}
</script>

<style lang="scss">
.copy-number {
  &.vs-button {
    background-color: rgba(var(--vs-color), 0.08);

    .vs-button__content {
      font-size: 14px;

      .icon--mask {
        background-color: rgb(var(--vs-color));
      }
    }

    &:focus,
    &:active {
      background-color: rgba(var(--vs-color), 1);
      color: var(--mn-bg);

      .icon--mask {
        background-color: var(--mn-bg);
      }
    }
  }

}
</style>