<template>
  <div ref="files" class="files flex flex--direction--column flex--grow--1 gap--12">
    <div
      class="files__breadcrumbs flex flex--content--start flex--items--center pointer f--14 text--dark gap--12 flex--wrap">
      <div class="files__breadcrumbs__item flex flex--center gap--8 nowrap"
        :class="{ active: i === breadcrumbs.length - 1 }" @click="setFolder($event, brd)" v-for="(brd, i) in breadcrumbs"
        :key="'brd-' + i">
        {{ brd.name }}
      </div>
    </div>

    <fs-viewer :slide="fsViwerCurrentSlide" type="image" :toggler="fsViewerOpen" :sources="getCurrentFolderFiles" />

    <div v-show="breadcrumbs.length > 1" @click="setPreviousFolder" class="flex flex--items--center gap--12 pointer">
      <vs-button class="bg--dark" dark>
        <div class="icon icon--mask icon--mask--arrow"></div>
      </vs-button>
      <span>Back</span>
    </div>

    <div class="files__current flex flex--direction--column flex--grow--1 gap--12">
      <template v-for="(item, i) in currentFolder.childs">

        <vs-button v-if="item.type === 'folder'" @click="moveToFolder($event, item)" :key="'item-' + i" block dark
          class="save-file f--14 bg--layout">
          <div class="flex flex--content--start flex--items--center gap--8 mr--auto w100">
            <div class="icon icon--mask icon--mask--folder"></div>
            <span class="flex--grow--1 text--left ellipsis">{{ item.name }}</span>
            <span class="files__count f--14 f--600">{{ getFilesAmount([item]) }} files</span>
            <vs-tooltip>
              <div @click="removeFolder($event, item)" class="files__remove icon icon--mask icon--mask--trash"></div>
              <template #tooltip>
                Delete
              </template>
            </vs-tooltip>
          </div>
        </vs-button>

        <vs-button v-if="item.type === 'file'" @click="openFile(item)" :key="'item-' + i" block dark
          class="save-file f--14 bg--layout">
          <div class="flex flex--content--start flex--items--center gap--8 mr--auto w100">
            <div class="icon icon--mask icon--mask--file"></div>
            <span class="flex--grow--1 text--left ellipsis">{{ item.name }}</span>
            <div v-if="!isImage(item.name) && !isPDF(item.name)" class="icon icon--mask icon--mask--external"></div>
            <vs-tooltip>
              <div @click="removeFile($event, item)" class="files__remove icon icon--mask icon--mask--trash"></div>
              <template #tooltip>
                Delete
              </template>
            </vs-tooltip>
          </div>
        </vs-button>

      </template>

      <div class="empty flex flex--center flex--grow--1" v-if="currentFolder.childs.length === 0">This folder is empty</div>

      <div v-if="isFolderCreating" class="files__add flex flex--items--center w100 gap--8">
        <div dark class="files__add__item w100 gap--8 flex flex--content--start flex--items--center">
          <div class="icon icon--mask icon--mask--folder"></div>
          <input @keyup.enter="createFolder" v-model="newFolderName" ref="new-folder-name" type="text"
            placeholder="New folder">
        </div>
        <vs-button @click="cancelFolderAdding" danger class="files__add__cancel f--14">
          Cancel
        </vs-button>
        <vs-button @click="createFolder" :disabled="!newFolderName" class="files__add__save f--14">
          Save
        </vs-button>
      </div>

      <vs-button :disabled="isFolderCreating" @click="addFolder" dark class="f--14 bg--layout files__add__control">+ Add
        folder</vs-button>

      <p-file-upload @before-send="onBeforeFileSend" @error="onFileUploadingError" @upload="onFileUpload" withCredentials
        multiple name="file[]" :url="getCurrentFolderFullPath" :maxFileSize="20_000_000" chooseLabel="Add">
        <template #empty>
          <div class="flex flex--center f--14 f--500 gap--12">
            <i class="pi pi-upload"></i>
            Drag and drop files to here to upload to the current folder
          </div>
        </template>
      </p-file-upload>
    </div>
  </div>
</template>

<script>
import FETCH from "@/utils/api";

const API = {
  base: process.env.VUE_APP_API_BASE
}
export default {
  props: {
    files: {
      require: false,
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      isLoading: false,
      isFolderCreating: false,
      newFolderName: '',
      deleteLoader: null,
      currentFolder: this.files,
      fsViewerOpen: false,
      fsViwerCurrentSlide: 2,
      breadcrumbs: [
        {
          name: 'Documents',
          id: -1
        }
      ]
    }
  },
  watch: {
    isLoading(val) {
      if (val) {
        this.deleteLoader = this.$vs.loading({
          target: this.$refs.files,
          text: 'Loading...'
        })
      } else {
        this.deleteLoader.close()
      }
    },
    breadcrumbs() {
      this.isFolderCreating = false
      this.newFolderName = ''
    }
  },

  computed: {
    getCurrentFolderFiles() {
      return this.currentFolder.childs
        .filter(item => item.type === 'file')
        .filter(item => {
          return this.isImage(item.name)
        })
        .map(item => item.path)
    },
    getCurrentFolderFullPath() {
      return `${API.base}user/upload-file?originalPath=${this.files.path}&path=${this.currentFolder.path}`
    },
  },
  methods: {
    moveToFolder(e, folder) {
      e.stopPropagation()

      this.currentFolder = folder
      this.breadcrumbs.push(folder)
    },

    setPreviousFolder() {
      this.breadcrumbs.pop()

      if (this.breadcrumbs.length === 1) {
        this.currentFolder = this.files
      } else {
        this.currentFolder = this.breadcrumbs[this.breadcrumbs.length - 1]
      }
    },

    setFolder(e, folder) {
      e.stopPropagation()

      if (folder.id === -1) {
        this.currentFolder = this.files
        this.breadcrumbs = [{
          name: 'Documents',
          id: -1
        }]
      } else {
        const index = this.breadcrumbs.indexOf(folder)
        if (index === this.breadcrumbs.length - 1) return
        this.breadcrumbs.splice(index + 1)
        this.currentFolder = folder
      }
    },

    addFolder() {
      this.isFolderCreating = true
      this.$nextTick(() => {
        this.$refs['new-folder-name'].focus()
      })
    },

    createFolder() {
      if (!this.newFolderName) return

      this.isLoading = true

      FETCH.request({
        method: 'post',
        url: `filesystem/create-folder?originalPath=${this.files.path}&path=${this.currentFolder.path}&folderName=${this.newFolderName}`
      })
        .then(res => {
          this.isFolderCreating = false
          this.newFolderName = ''

          this.updateNewFiles(res.data)
        })
        .catch(err => {
          console.log(err)

          this.$vs.notification({
            flat: true,
            color: 'danger',
            title: 'Error',
            text: 'Folder creating error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    cancelFolderAdding(e) {
      e.stopPropagation()
      this.isFolderCreating = false
      this.newFolderName = ''
    },

    isImage(url) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url.toLowerCase())
    },

    isPDF(url) {
      return /\.(pdf)$/.test(url.toLowerCase());
    },

    openFile(file) {
      if (this.isImage(file.name)) {
        const index = this.getCurrentFolderFiles.indexOf(file.path)

        this.fsViwerCurrentSlide = index + 1
        this.fsViewerOpen = !this.fsViewerOpen
      } else if (this.isPDF(file.name)) {
        this.$emit('onPDFClick', file.path)
      } else {
        window.open(file.path, '_blank').focus()
      }
    },

    removeFile(e, file) {
      e.stopPropagation();
      this.isLoading = true

      FETCH.request({
        method: 'delete',
        url: `contract/file?originalPath=${this.files.path}&path=${this.currentFolder.path}/${file.name}`
      })
        .then(res => {
          this.updateDeletedFiles(res.data)
        })
        .catch(err => {
          console.log(err)

          this.$vs.notification({
            flat: true,
            color: 'danger',
            title: 'Error',
            text: 'File deleting error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    removeFolder(e, folder) {
      e.stopPropagation();

      this.isLoading = true

      FETCH.request({
        method: 'delete',
        url: `filesystem/remove-folder?originalPath=${this.files.path}&path=${this.currentFolder.path}&folderName=${folder.name}`
      })
        .then(res => {
          this.updateDeletedFiles(res.data)
        })
        .catch(err => {
          console.log(err)

          this.$vs.notification({
            flat: true,
            color: 'danger',
            title: 'Error',
            text: 'Folder deleting error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onBeforeFileSend(e) {
      this.isLoading = true
      const { xhr } = e
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.$store.getters.TOKEN);
    },

    onFileUploadingError(event) {
      this.isLoading = false

      const { files } = event

      files.forEach(file => {
        this.$vs.notification({
          flat: true,
          color: 'danger',
          title: 'Error',
          text: 'File uploading error'
        })
      })
    },

    onFileUpload(event, path) {
      this.isLoading = false

      const { xhr } = event
      const { response } = xhr

      try {
        const data = JSON.parse(response)
        this.updateNewFiles(data)
      }
      catch (err) {
        console.log(err)
      }
    },

    updateNewFiles(newFiles) {
      console.log('Filesystem updated on server, try to update component.')

      if (this.files.path !== newFiles.path) {
        throw new Error('Root pathes not equals, probably files directory was changed')
      }

      const compareChilds = (origin, updated) => {
        updated
          .forEach(u => {
            if (u.type === 'file') {
              const exist = origin
                .filter(o => o.type === 'file')
                .find(o => o.name === u.name)

              if (!exist) {
                origin.push(u)

                this.$vs.notification({
                  text: 'File uploaded successfully',
                })
              }
            }

            if (u.type === 'folder') {
              const originFolder = origin.find(o => o.name === u.name)

              if (originFolder) {
                compareChilds(originFolder.childs, u.childs)
              } else {
                origin.push(u)

                this.$vs.notification({
                  text: 'Folder created successfully',
                })
              }

            }

          })
      }

      compareChilds(this.files.childs, newFiles.childs)
    },

    updateDeletedFiles(newFiles) {
      console.log('Filesystem updated on server, try to update component.')

      if (this.files.path !== newFiles.path) {
        throw new Error('Root pathes not equals, probably files directory was changed')
      }

      const compareChilds = (origin, updated) => {
        origin
          .forEach(o => {
            if (o.type === 'file') {
              const exist = updated
                .filter(u => u.type === 'file')
                .find(u => u.name === o.name)

              if (!exist) {
                origin.splice(origin.indexOf(o), 1)

                this.$vs.notification({
                  text: 'File deleted successfully',
                })
              }
            }

            if (o.type === 'folder') {
              const updatedFolder = updated.find(u => u.name === o.name)

              if (updatedFolder) {
                compareChilds(o.childs, updatedFolder.childs)
              } else {
                origin.splice(origin.indexOf(o), 1)

                this.$vs.notification({
                  text: 'Folder deleted successfully',
                })
              }
            }

          })
      }

      compareChilds(this.files.childs, newFiles.childs)
    },
  }
}
</script>

<style lang="scss">
.files {
  overflow-x: hidden;
  overflow-y: auto;

  &__breadcrumbs {
    row-gap: 4px;

    &__item {
      transition: all 0.25s ease-in-out;
      position: relative;

      &:not(:last-child) {
        &::after {
          content: "";
          width: 6px;
          height: 6px;
          border-right: 2px solid var(--mn-text-dark);
          border-bottom: 2px solid var(--mn-text-dark);
          transform: rotate(-45deg);
        }
      }

      &.active {
        color: var(--mn-text);
        cursor: default;
      }

      &:hover {
        color: var(--mn-text);
      }
    }
  }

  &__current {
    padding: 4px 0;
    overflow: auto;
    padding-right: 8px;
    width: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: var(--mn-dark);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--mn-blue);
      border-radius: 2px;
    }

    .vs-button {
      border: none;

      &:hover {
        .files__count {
          color: var(--mn-text);
        }

        .icon--mask--external {
          background-color: var(--mn-text);
        }
      }

      .files__count {
        color: var(--mn-text-dark);
      }
    }

    .p-fileupload {
      margin-top: auto;
    }

  }

  &__add {

    &__control {
      width: 120px;
      align-self: end;
    }

    &__item {
      height: 40px;
      background-color: var(--mn-layout);
      border-radius: 8px;
      padding: 0 12px;

      input[type=text] {
        font-size: 14px;
        font-weight: 600;
        border: none;
        background: transparent !important;
        padding: 0;
      }
    }

    &__save,
    &__cancel {
      &.vs-button {
        min-width: 80px;
        min-height: 36px;
        height: 36px;

        .icon--mask {
          min-width: 18px;
          height: 18px;
          background-color: var(--mn-bg);
        }
      }
    }
  }

  &__remove {
    &:hover {
      background-color: var(--mn-red);
    }
  }

  @include _576 {
    &__add {
      flex-wrap: wrap;
      justify-content: flex-end;

      &__control {
        width: 100%;
      }
    }
  }
}
</style>