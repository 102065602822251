import axios from "axios"
import router from '@/router'

const setup = (store) => {
  axios.interceptors.request.use(
    (config) => {

      if (store.getters.TOKEN) {
        config.headers["Authorization"] = 'Bearer ' + store.getters.TOKEN
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  )

  axios.interceptors.response.use(undefined,
    async err => {
      const originalConfig = err.config
      const url = originalConfig.url

      if (url === 'token/invalidate' || url === 'token/refresh') {
        return Promise.reject(`Can not identify user`)
      }

      if (url !== 'login' && err.response) {
        if (err.response.status === 401 && !originalConfig.__isRetryRequest) {
          originalConfig.__isRetryRequest = true
          try {
            await store.dispatch('AUTH_REFRESH')
            return axios(originalConfig)
          }
          catch (err) {
            await store.dispatch('AUTH_LOGOUT')
            router.push({ name: 'login' })
            return Promise.reject(err)
          }
        }
      }

      return Promise.reject(err);

    })
}

export default setup