import axios from 'axios'
import store from "../store"
import helpers from './helpers';

axios.defaults.baseURL = process.env.VUE_APP_API_BASE
// axios.defaults.timeout = 500;
axios.defaults.withCredentials = true

export default {
  request({ url, method, data, cancelable, params }) {

    console.log('API call: ' + url)

    let config = {
      method,
      url,
      data
    }

    if (params) {
      config.params = params
    }

    if (cancelable) {
      const controller = new AbortController()
      config.signal = controller.signal

      store.commit('ADD_BLOCK_REQUEST', {
        id: helpers.getRandomInt(1_000_000),
        controller
      })
    }

    return axios(config)
  }
}