import API from "../../utils/api";

const THEME_KEY = 'mn-theme'
const DEFAULT_THEME = 'dark'

const state = {
  loading: false,
  blockRequests: [],
  theme: localStorage.getItem(THEME_KEY) || DEFAULT_THEME,
  requestsPending: 0
}

const getters = {
  IS_LOADING: state => state.loading,
  BLOCK_REQUESTS: state => state.blockRequests,
  THEME: state => state.theme,
  REQUESTS_PENDING: state => state.requestsPending
}


const actions = {
  GET_DASHBOARD_INFO: ({ commit }) => {
    return new Promise((resolve, reject) => {
      API.request({
        method: 'get',
        url: 'dashboard'
      })
        .then(res => {
          commit('SET_REQUESTS_PENDING', res.data.requests.pending)
          resolve(res.data)
        })
        .catch(err => reject(err))
    })
  }
}


const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading
  },

  ADD_BLOCK_REQUEST: (state, request) => {
    state.blockRequests.push(request)
  },

  REMOVE_BLOCK_REQUEST: (state, id) => {
    state.blockRequests = state.blockRequests.filter(request => request.id !== id)
  },

  SET_THEME: (state, theme) => {
    state.theme = theme
    localStorage.setItem(THEME_KEY, theme)
  },

  SET_REQUESTS_PENDING: (state, count) => {
    state.requestsPending = count
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};