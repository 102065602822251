import ROLES from '@/utils/roles.json'

const permissionRules = [
  {
    role: ROLES.ADMIN,
    restricted: {
      routes: []
    }
  },
  {
    role: ROLES.SALES,
    restricted: {
      routes: [
        'users'
      ]
    }
  },
  {
    role: ROLES.MECHANIC,
    restricted: {
      routes: [
        'users'
      ]
    }
  }
]

export { permissionRules }