import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', str => {
  return moment(str).format('DD MMM YYYY')
})

Vue.filter('formatNumber', x => {
  try {
    return Number(x)
  } catch (err) {
    return '-'
  }
})

Vue.filter('formatEmpty', str => {
  if (!str) return '-'
  return str
})

Vue.filter('formatContractStatus', status => {
  if (status === '0') return 'Reserved'
  if (status === '1') return 'Out'
  if (status === '2') return 'Returned'
  if (status === '3') return 'Completed'

  return status
})

Vue.filter('getAvatarLetters', name => {
  if (name && name.length) {
    let AB = ''
    name
      .split(' ')
      .forEach((word, index) => {
        if (index > 1) {
          return
        }

        AB += word.charAt(0).toUpperCase()
      })

    return AB
  }
  return ""
})