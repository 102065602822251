<template>
  <div class="sidebar" ref="sidebar" :class="{ 'sidebar--open': open }">

    <div @click="hide" class="sidebar__bg"></div>

    <nav ref="nav" v-touch:swipe.left="onNavSwipeLeft" v-touch:swipe.bottom="onNavSwipeBottom"
      class="flex flex--direction--column gap--16">
      <div class="mobile__swiper"></div>
      <div class="sitename flex flex--center">
        <div v-show="THEME === 'light'" class="icon icon--bg icon--bg--logo-light"></div>
        <div v-show="THEME === 'dark'" class="icon icon--bg icon--bg--logo-dark"></div>
      </div>

      <div class="border username flex gap--12 flex--items--center">
        <div class="avatar flex flex--center f--14">{{ username | getAvatarLetters }}</div>
        {{ username }}
      </div>

      <ul class="flex flex--direction--column flex--grow--1">
        <router-link class="flex flex--items--center" to="/dashboard">
          <div class="icon icon--mask icon--mask--dashboard"></div>
          <span>Dashboard</span>
        </router-link>
        <router-link class="flex flex--items--center" to="/assets">
          <div class="icon icon--mask icon--mask--assets"></div>
          <span>Assets</span>
        </router-link>
        <router-link class="flex flex--items--center" to="/contracts">
          <div class="icon icon--mask icon--mask--contract"></div>
          <span>Contracts</span>
        </router-link>
        <router-link class="flex flex--items--center" to="/requests">
          <div class="icon icon--mask icon--mask--request"></div>
          <span>Requests</span>
          <div v-show="REQUESTS_PENDING" class="icon icon--mask icon--mask--green-dot"></div>
        </router-link>
        <router-link v-if="isAdmin" class="flex flex--items--center" to="/users">
          <div class="icon icon--mask icon--mask--user"></div>
          <span>Users</span>
        </router-link>
      </ul>
      <div @click="logout" class="logout flex flex--items--center">
        <div class="icon icon--mask icon--mask--logout"></div>
        <span>Log out</span>
      </div>
    </nav>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    username: {
      type: String,
      required: false
    },
    open: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: mapGetters(['THEME', 'REQUESTS_PENDING']),
  methods: {
    onNavSwipeLeft() {
      if (this.isTablet() && !this.isMobile()) {
        this.$emit('update:open', false)
      }
    },
    onNavSwipeBottom(e) {
      if (this.isMobile) {
        this.$emit('update:open', false)
      }
    },
    hide() {
      this.$emit('update:open', false)
    },
    logout() {
      this.$emit('onLogout')
    }
  }
}
</script>

<style lang="scss">
:root[mn-theme="light"] {
  .sidebar {
    nav {
      background-color: var(--mn-dark);

      .sitename {
        border-bottom-color: var(--mn-bg);
      }
    }
  }
}

.sidebar {
  --width: 320px;

  nav {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: var(--width);
    background-color: var(--mn-layout);
    padding: 0 12px 40px 12px;
    z-index: 4;
    overflow-x: hidden;
    overflow-y: auto;

    .mobile__swiper {
      display: none;
    }

    .username {
      gap: 12px;
    }

    .sitename {
      height: 66px;
      margin: 0 -12px;
      border-bottom: 1px solid var(--mn-dark);
    }

    ul {
      padding-top: 120px;
      gap: 8px;

      a {
        height: 56px;
        width: 100%;
        gap: 12px;
        transition: all 0.25s ease-in-out;
        border-radius: 12px;
        border: 1px solid transparent;
        padding: 0 16px;

        &:hover {
          border-color: var(--mn-border);
        }

        &.active {
          background-color: var(--mn-bg);
          border-color: var(--mn-border);
        }

        .icon--mask:not(.icon--mask--green-dot) {
          width: 14px;
          height: 14px;
        }

      }
    }

    .logout {
      gap: 12px;
      cursor: pointer;
      padding: 16px;
      border-radius: 12px;
      border: 1px solid transparent;
      transition: all 0.25s ease-in-out;

      &:hover {
        border-color: var(--mn-border);
      }

      .icon--mask {
        width: 18px;
        height: 18px;
      }
    }

  }

  @include _992 {
    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      cursor: pointer;
      transition: opacity 0.25s ease-in-out;
      opacity: 0;
      z-index: -999;
      visibility: hidden;
      @include background(#000, var(--mn-dialog-opacity));
    }

    nav {
      opacity: 0;
      transform: translateX(calc(0px - var(--width)));
      transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    }

    &.sidebar--open {
      nav {
        transform: translateX(0);
        opacity: 1;
      }

      .sidebar__bg {
        opacity: 1;
        z-index: 3;
        visibility: visible;
      }
    }
  }

  @include _576 {

    nav {
      transform: translateX(0) translateY(100vh);
      height: auto;
      top: auto;
      bottom: 0;
      width: 100%;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      padding: 48px 12px 20px 12px;
      @include border;

      .mobile__swiper {
        width: 57px;
        height: 3px;
        border-radius: 2px;
        background-color: var(--mn-text);
        top: 12px;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        display: block;
      }

      .sitename {
        display: none;
      }

      ul {
        padding-top: 16px;
        border-top: 1px solid var(--mn-border);
      }

      .logout {
        margin-top: -12px;
      }

    }
  }

  @include landspace {
    nav {
      ul {
        padding-top: 40px;
      }
    }
  }

}
</style>