import API from "../../utils/api";

const state = {
  assets: [],
  assetCategories: null
}

const getters = {
  ASSETS: state => state.assets,
  ASSETS_CATEGORIES: state => state.assetCategories
}

const actions = {
  GET_ASSETS({ commit }, status) {
    return new Promise((resolve, reject) => {
      commit('SET_LOADING', true)

      API.request({
        method: 'get',
        url: 'asset',
        cancelable: true,
        params: { status }
      })
        .then(res => {
          commit('SET_ASSETS', res.data)
          commit('SET_LOADING', false)
          resolve(res.data)
        })
        .catch(err => {
          if (err.code === 'ERR_CANCELED')
            return

          commit('SET_LOADING', false)
          console.log(err)
          reject(err)
        })
    })

  },

  GET_ASSET({ commit }, autoid) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'get',
        url: 'asset/' + autoid,
        cancelable: true,
      })
        .then(res => {
          resolve(res.data)
          commit('SET_LOADING', false)
        })
        .catch(err => {
          if (err.code === 'ERR_CANCELED')
            return

          commit('SET_LOADING', false)
          reject(err)
        })
    })
  },

  SEND_MACHINE_DOWN({ commit }, { autoid, reason }) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'machine-down-report',
        data: {
          asset: autoid,
          reason
        }
      })
        .then(res => {
          if (res.data.result === false) return reject('')
          return resolve()
        })
        .catch(err => reject(err))
        .finally(() => commit('SET_LOADING', false))
    })
  },

  GET_ASSETS_CATEGORIES({ commit }) {

    API.request({
      method: 'get',
      url: 'asset/categories'
    })
      .then(res => {
        commit('SET_ASSETS_CATEGORIES', res.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

const mutations = {
  SET_ASSETS: (state, assets) => {
    state.assets = assets
  },

  SET_ASSETS_CATEGORIES: (state, categories) => {
    state.assetCategories = categories
    state.assetCategories.unshift('All')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};