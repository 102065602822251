<template>
  <vs-dialog @close="onClose" :loading="IS_LOADING" width="640px" not-center :value="active">
    <div class="dialog border flex flex--direction--column gap--24">
      <div class="f--20 f--700">Machine Down reason</div>
      <div class="flex flex--direction--column gap--10">
        Write a problem with machine
        <div class="input__group input__group--ha w100">
          <textarea ref="reason" class="rows--6" placeholder="Notes field" v-model="reason"></textarea>
        </div>
      </div>
      <div class=" w100 flex gap--12">
        <vs-button @click="send" danger block>Send</vs-button>
      </div>
    </div>
  </vs-dialog>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    autoid: {
      type: String,
      required: true
    }
  },
  methods: {
    onClose() {
      this.$emit('update:active', false)
    },
    send() {
      if (this.reason) {
        this.$refs['reason'].classList.remove('error')

        this.$store.dispatch('SEND_MACHINE_DOWN', {
          reason: this.reason,
          autoid: this.autoid
        })
          .then(res => this.$emit('success'))
          .catch(err => this.$emit('error'))
          .finally(() => this.onClose())
      } else {
        this.$refs['reason'].classList.add('error')
      }
    }
  },
  data() {
    return {
      reason: ""
    }
  },
  computed: mapGetters(['IS_LOADING'])
}
</script>