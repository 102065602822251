import Vue from "vue";
import API from "@/utils/api";
import ROLES from '@/utils/roles.json'

// 18da05f3
const state = {
  user: null,
  users: [],
  userRoles: [
    {
      role: ROLES.ADMIN,
      title: 'Admin',
      color: 'green'
    },
    {
      role: ROLES.SALES,
      title: 'Sales',
      color: 'pink'
    },
    {
      role: ROLES.MECHANIC,
      title: 'Technician',
      color: 'yellow'
    }
  ]
}

const getters = {
  USER: state => state.user,
  USERS: state => state.users,
  USER_ROLES: state => state.userRoles
}

const actions = {
  GET_USER: ({ commit }) => {
    return new Promise((resolve, reject) => {
      API.request({
        method: 'get',
        url: 'user/me'
      })
        .then(res => {
          commit('SET_USER', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
    })

  },

  async GET_USERS({ commit }) {
    commit('SET_LOADING', true)

    await API.request({
      method: 'get',
      url: 'user',
      cancelable: true,
    })
      .then(res => {
        commit('SET_USERS', res.data)
        commit('SET_LOADING', false)
      })
      .catch(err => {
        if (err.code === 'ERR_CANCELED')
          return

        commit('SET_LOADING', false)
      })

  },

  EDIT_USER({ commit }, { id, user }) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'patch',
        url: 'user/' + id,
        data: user,
        cancelable: true
      })
        .then(res => {
          if (res.data.result === false) {
            commit('SET_LOADING', false)
            return reject(res.data.error?.detail)
          }

          resolve(res.data)
          commit('SET_LOADING', false)
        })
        .catch(err => {
          if (err.code === 'ERR_CANCELED')
            return

          commit('SET_LOADING', false)
          reject(err)
        })
    })
  },

  CREATE_USER({ commit }, user) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'user',
        data: user,
        cancelable: true
      })
        .then(res => {
          if (res.data.result === false) {
            commit('SET_LOADING', false)
            return reject(res.data.error?.detail)
          }

          resolve(res.data)
          commit('SET_LOADING', false)
        })
        .catch(err => {
          if (err.code === 'ERR_CANCELED')
            return

          commit('SET_LOADING', false)
          reject(err)
        })
    })
  },

  CHANGE_USER_STATUS({ commit }, { id, isActive }) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'patch',
        url: 'user/' + id,
        data: {
          isActive
        }
      })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
        .finally(() => {
          commit('SET_LOADING', false)
        })
    })
  }
}

const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_USERS: (state, users) => {
    state.users = users.sort((a, b) => (a.id > b.id) ? 1 : -1)
  },
  REMOVE_USER(state) {
    state.user = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};