<template>
  <div class="status view__info flex flex--center flex--direction--column flex--grow--1">
    <div class="border w100 flex flex--direction--column gap--40">
      <div class="w100 flex flex--direction--column gap--8">
        <div class="flex flex--items--center gap--12">
          <div class="icon icon--mask" :class="'icon--mask--' + status"></div>
          <div class="f--700 f--20">{{ title }}</div>
        </div>
        <div class="text--dark f--400">
          {{ text }}
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    title: String,
    text: String
  }
}
</script>