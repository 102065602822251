const styles = `
      background: #50a1ff; 
      padding: 1px 6px; font-size: 
      10px; border-radius: 2px; 
      color: #212121;`

const originLog = console.log

console.log = function (message) {
  if (process.env.NODE_ENV === 'development' || window.location.href.includes('debug=1')) {
    originLog(`%cmn`, styles, ...arguments)
  }
}