import API from "../../utils/api";
import TokenService from '@/utils/tokenService'

const REMEMBER_KEY = 'mdnth-rm'

const state = {
  token: TokenService.find(),
  remember: Boolean(window.localStorage.getItem(REMEMBER_KEY))
}

const getters = {
  TOKEN: state => state.token
}

const actions = {
  AUTH_REQUEST: ({ commit }, user) => {

    commit('SET_LOADING', true)

    if (user.remember) {
      commit('REMEMBER_USER')
    }

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'login',
        data: user
      })
        .then(res => {
          commit('SET_TOKEN', res.data.token)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    })
  },
  AUTH_REFRESH: ({ commit }) => {
    return new Promise((resolve, reject) => {
      API.request({
        method: 'get',
        url: 'token/refresh'
      })
        .then(res => {
          const token = res.data.token
          commit('SET_TOKEN', token)
          resolve(token)
        })
        .catch(err => reject(err))
    })
  },
  AUTH_LOGOUT: ({ commit }) => {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'token/invalidate'
      })
        .finally(() => {
          commit('REMOVE_TOKEN')
          commit('REMOVE_USER')
          commit('FORGOT_USER')
          TokenService.remove()
          commit('SET_LOADING', false)
          resolve()
        })
        .catch(err => {
          console.log(err)
        })
    })
  },
  AUTH_RESET: ({ commit }, email) => {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'reset-password',
        data: { email }
      })
        .then(res => {
          if (res.data.result === false) return reject(res.data.error)
          return resolve(res)
        })
        .catch(err => reject(err))
        .finally(() => {
          commit('SET_LOADING', false)
        })
    })
  },

  AUTH_RESET_APPROVE: ({ commit }, hash) => {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'reset-password/hash',
        data: { hash }
      })
        .then(res => {
          if (res.data.result === false) return reject(res.data.error)
          return resolve()
        })
        .catch(err => console.log(err))
        .finally(() => {
          commit('SET_LOADING', false)
        })
    })
  }
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    TokenService.set(token, state.remember)
  },

  REMOVE_TOKEN: (state) => {
    state.token = "";
  },

  REMEMBER_USER: state => {
    state.remember = true
    window.localStorage.setItem(REMEMBER_KEY, 1)
  },

  FORGOT_USER: state => {
    state.remember = false
    window.localStorage.removeItem(REMEMBER_KEY)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}