import Cookie from 'js-cookie'

const NAME = 'mdnth-jwt'

export default {
  set(token, remember) {
    if (remember) {
      window.localStorage.setItem(NAME, token)
    } else {
      Cookie.set(NAME, token)
    }
  },

  remove() {
    window.localStorage.removeItem(NAME)
    Cookie.remove(NAME)
  },

  find() {
    let token

    token = window.localStorage.getItem(NAME)

    if (token) {
      console.log('JWT founded in localStorage')
    } else {
      token = Cookie.get(NAME)

      if (token) {
        console.log('JWT founded in cookie')
      }
    }

    if (!token) console.log('JWT not found')

    return token
  }
}