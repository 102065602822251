<template>
  <iframe :src="getFullPath" width="100%" height="100%" class="pdf-viewer"></iframe>
</template>

<script>

const BASE = '/pdf/web/viewer.html'

export default {
  props: {
    path: String
  },
  computed: {
    getFullPath() {
      return `${BASE}?file=${encodeURIComponent(this.path)}`
    }
  }
}
</script>