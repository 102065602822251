import API from "../../utils/api";

const state = {
  requests: []
};

const getters = {
  REQUESTS: state => state.requests
};

const actions = {
  async GET_REQUESTS({ commit }, status) {
    commit('SET_LOADING', true)

    await API.request({
      method: 'get',
      url: 'request',
      cancelable: true,
      params: { status }
    })
      .then(res => {
        commit('SET_REQUESTS', res.data)
        commit('SET_LOADING', false)
      })
      .catch(err => {
        if (err.code === 'ERR_CANCELED')
          return

        commit('SET_LOADING', false)
        console.log(err)
      })

  },

  GET_REQUEST({ commit }, id) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'get',
        url: 'request/' + id,
        cancelable: true,
      })
        .then(res => {
          commit('SET_LOADING', false)
          resolve(res.data)
        })
        .catch(err => {
          if (err.code === 'ERR_CANCELED')
            return

          commit('SET_LOADING', false)
          reject(err)
        })
    })
  },

  REQUEST_DECLINE({ commit }, { id, declineReason }) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'request/decline/' + id,
        data: {
          declineReason
        }
      })
        .then(res => {
          if (res.data.result === false) return reject('Server validation Error')
          return resolve()
        })
        .catch(err => reject(err))
        .finally(() => commit('SET_LOADING', false)
        )
    })
  },

  REQUEST_APPROVE({ commit }, id) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'request/approve/' + id
      })
        .then(res => resolve())
        .catch(err => reject(err))
        .finally(() => commit('SET_LOADING', false))
    })
  },

  SEND_REQUEST({ commit }, request) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'post',
        url: 'request',
        data: request,
        cancelable: true
      })
        .then(res => {
          resolve(res.data)
          commit('SET_LOADING', false)
        })
        .catch(err => {
          if (err.code === 'ERR_CANCELED')
            return

          commit('SET_LOADING', false)
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_REQUESTS: (state, requests) => {
    state.requests = requests
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};