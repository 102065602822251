export default {
  getRandomInt(max, min = 0) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  },

  async copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) { }
  },

  copyObject(obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  isValidEmail(str) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str)
  }
}