import API from "@/utils/api"

const state = {
  contracts: []
}

const getters = {
  CONTRACTS: state => state.contracts
}

const actions = {
  async GET_CONTRACTS({ commit }, status) {
    commit('SET_LOADING', true)

    await API.request({
      method: 'get',
      url: 'contract',
      cancelable: true,
      params: { status }
    })
      .then(res => {
        commit('SET_CONTRACTS', res.data)
        commit('SET_LOADING', false)
      })
      .catch(err => {
        if (err.code === 'ERR_CANCELED')
          return

        commit('SET_LOADING', false)
        console.log(err)
      })

  },

  GET_CONTRACT({ commit }, autoid) {
    commit('SET_LOADING', true)

    return new Promise((resolve, reject) => {
      API.request({
        method: 'get',
        url: 'contract/' + autoid,
        cancelable: true,
      })
        .then(res => {
          resolve(res.data)
          commit('SET_LOADING', false)
        })
        .catch(err => {
          if (err.code === 'ERR_CANCELED')
            return

          commit('SET_LOADING', false)
          reject(err)
        })
    })
  }
}

const mutations = {
  SET_CONTRACTS: (state, contracts) => {
    state.contracts = contracts
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};