<template>
  <vs-button @click="download" block dark class="save-file f--14 bg--dark">
    <div class="flex flex--content--start flex--items--center gap--8 mr--auto">
      <div class="icon icon--mask icon--mask--file"></div>
      <span>{{ name }}</span>
    </div>
  </vs-button>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    extension: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false,
      default: 'Document'
    }
  },
  methods: {
    download() {
      axios({
        url: this.url,
        method: 'get',
        responseType: 'arraybuffer',
      })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `file.${this.extension}`)
          document.body.appendChild(link)
          link.click()
        })
    }
  }
}
</script>