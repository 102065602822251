import Vue from 'vue'
import copyNumber from '@/elements/copyNumber.vue'
import saveFile from '@/elements/saveFile.vue'
import reportMachineDown from '@/elements/reportMachineDown.vue'
import status from '@/elements/status.vue'
import files from '@/elements/files.vue'
import PDFViewer from '@/elements/PDFViewer.vue'
import fileViewer from '@/elements/fileViewer.vue'

import DatePicker from 'vue2-datepicker';
import FsLightbox from "fslightbox-vue/v2";
import PButton from 'primevue/button';
import PFileUpload from 'primevue/fileupload';

Vue.component('mn-copy-number', copyNumber)
Vue.component('save-file', saveFile)
Vue.component('report-machine-down', reportMachineDown)
Vue.component('mn-status', status)
Vue.component('mn-files', files)
Vue.component('mn-pdf-viewer', PDFViewer)
Vue.component('mn-file-viewer', fileViewer)

Vue.component('date-picker', DatePicker)
Vue.component('fs-viewer', FsLightbox)
Vue.component('p-file-upload', PFileUpload)